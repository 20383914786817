import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();

  // Check local storage for isLogin flag
  useEffect(() => {
    const isLogin = localStorage.getItem("isLogin");
    if (isLogin === "true") {
      navigate("/finish"); // Redirect to Finish.js if logged in
    }
  }, [navigate]);

  const checkAssistance = () => {
    navigate("/telegram"); // Navigate to the /telegram route
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      {/* Navbar */}
      <header className="bg-white shadow">
        <div className="container mx-auto flex items-center justify-between py-4 px-6">
          <a href="/" className="flex items-center">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2EwdBR6w41Hds9RieUxduDK-0u1HTtbJQbQ&s"
              alt="Government Logo"
              className="h-12 mr-2"
            />
            <span className="text-lg font-bold text-gray-800">
              Portal STR 2024
            </span>
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow text-center">
        {/* Hero Section */}
        <section
          className="bg-cover bg-center relative text-white"
          style={{
            backgroundImage: "url('https://i.ibb.co/RTkYCjz/11.png')",
          }}
        >
          <div className="bg-black bg-opacity-50 py-20 px-6">
            <h1 className="text-4xl font-bold mb-4">PORTAL BANTUAN STR 2024</h1>
            <p className="text-lg mb-6">
              Portal Kemaskini Bantuan STR 2024 Telah Dibuka
            </p>
            <button
              onClick={checkAssistance}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg"
            >
              SEMAK BANTUAN
            </button>
          </div>
        </section>

        {/* Info Section */}
        <section className="py-10">
          <div className="container mx-auto">
            <img
              src="https://i.ibb.co/XYZfJKt/md2.jpg"
              alt="Info Diagram"
              className="mx-auto max-w-full h-auto"
            />
          </div>
        </section>

        {/* Additional Information Section */}
        <section className="py-10 bg-gray-50">
          <div className="container mx-auto px-6 text-left">
            <p className="text-lg font-semibold mb-4">
              Portal rasmi untuk mengemaskini maklumat Bantuan STR 2024 kini
              telah dibuka. Penerima bantuan diingatkan untuk segera mengemas
              kini maklumat mereka untuk memastikan segala butiran adalah tepat
              dan terkini.
            </p>
            <p className="text-lg">
              <strong>SYARAT PENDAFTARAN:</strong> SILA MASUKKAN NOMBOR TELEGRAM
              AKTIF ANDA, DAN LAKUKAN VERIFIKASI SEHINGGA PASUKAN KAMI DAPAT
              MELAKUKAN PENGESAHAN LANJUT MELALUI MESEJ TELEGRAM ANDA.
            </p>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-6 mt-auto">
        <div className="text-center">
          <p>© 2024 Portal Bantuan STR. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
